<template>
  <div class="pb-5 mb-2">
    <div class="d-flex align-items-center mb-4">
      <div class="col-md-6">
        <a
          @click="$router.back()"
          class="text-dark d-flex align-items-center cursor-pointer"
        >
          <span class="d-flex align-items-center">
            <vs-icon icon="chevron_left"></vs-icon>
          </span>
          <span class="text-underline">Back</span>
        </a>
      </div>
      <div class="col-md-6">
        <div class="text-right">
          <button
            class="btn btn-primary btn-site-outline px-3"
            @click="$router.push('/users')"
          >
            View all Users
          </button>
        </div>
      </div>
    </div>

    <div>
      <h1 class="text-dark font-weight-bold">Create Admin User</h1>

      <div class="row">
        <div class="col-md-4">
          <div class="card siteCard">
            <div class="card-body">
              <form @submit.prevent="createAdminUser" class="mt-1">
                <div class="form-group">
                  <label for="name" class="text-dark">Name</label>
                  <input
                    type="text"
                    required
                    class="form-control"
                    id="name"
                    placeholder="Enter name"
                    v-model="adminUser.name"
                  />
                </div>
                <div class="form-group">
                  <label for="phone" class="text-dark">Phone</label>
                  <input
                    class="form-control"
                    id="phone"
                    placeholder="Enter phone"
                    v-model="adminUser.phone"
                  />
                </div>
                <div class="form-group">
                  <label for="email" class="text-dark">Email address</label>
                  <input
                    type="email"
                    required
                    class="form-control"
                    id="email"
                    placeholder="Enter email"
                    v-model="adminUser.email"
                  />
                </div>
                <div class="form-group">
                  <label for="password" class="text-dark">Password</label>
                  <input
                    type="password"
                    required
                    class="form-control"
                    id="password"
                    placeholder="Enter password"
                    v-model="adminUser.password"
                  />
                </div>
                <div class="form-group">
                  <label for="password_confirmation" class="text-dark">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    required
                    class="form-control"
                    id="password_confirmation"
                    placeholder="Confirm password"
                    v-model="adminUser.password_confirmation"
                  />
                </div>
                <div class="form-group">
                  <label for="role" class="text-dark">Role</label>
                  <select
                    class="form-control"
                    id="role"
                    v-model="adminUser.role"
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="subadmin">Sub-Admin</option>
                  </select>
                </div>
                <div class="form-group mt-5">
                  <button
                    :disabled="loading"
                    type="submit"
                    class="btn btn-primary btn-block btn-site px-5"
                  >
                    Create Admin User
                    <BtnLoading
                      v-if="loading"
                      class="d-inline-block"
                      height="18"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "../../../components/BtnLoading.vue";
export default {
  name: "CreateAdmin",
  data() {
    return {
      loading: false,
      adminUser: {
        name: "",
        email: "",
        password: "",
        phone: "",
        password_confirmation: "",
        role: "",
        status: "",
      },
    };
  },
  components: {
    BtnLoading,
  },
  methods: {
    createAdminUser() {
      this.$vs.loading();
      this.loading = true;
      console.log(this.adminUser);

      let fetch = {
        path: `admin/staff/store`,
        data: this.adminUser,
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Admin User created successfully",
            "Success",
            this.$toastPosition
          );
          setTimeout(() => {
            this.$router.push("/users");
          }, 1000);
          this.loading = false;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.loading = false;
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Admin User Creation Failed",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Admin User Creation Failed",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
